@import "./src/css/variables";

.report-block {
    .pretitle {
        font-size: 16px;
        color: rgba($heavymetal, 0.6);
        font-weight: 700;
        margin-bottom: 5px;
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        color: $heavymetal;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-weight: 700;
        margin-bottom: 10px;

        &-left {
            color: rgba($heavymetal, 0.65);
            font-size: 16px;
        }

        &-right {
            font-size: 14px;
            color: rgba($heavymetal, 0.5);
        }
    }

    .condition {
        font-size: 14px;
        color: rgba($heavymetal, 0.8);
        font-weight: 600;
        padding: 15px;
        background-color: aliceblue;
        margin-bottom: 15px;

        &-heading {

        }

        &-value {
            font-weight: 700;
            color: #1D1E1D;
            display: flex;
        }
    }

    .answers {
        font-size: 14px;
        color: rgba($heavymetal, 0.8);
        font-weight: 600;

        &-subtitle {
            white-space: nowrap;
            flex-wrap: nowrap;
        }

        &-row {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            align-items: center;
            width: 100%;

            &-text {
                font-weight: 700;
                display: flex;
                align-items: center;
                max-width: 300px;
            }

            &-percentage {
                display: flex;
                align-items: center;

                span {
                    margin-left: 5px;
                    color: rgba($heavymetal, 0.6);
                    font-weight: 700;
                    font-size: 12px;
                    width: 33px;
                    text-align: right;
                }
            }
        }
    }
}