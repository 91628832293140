@import "./src/css/variables";

.filter-modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 30%;

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 48px 2em 48px;

    .MuiFormControl-root {
      padding-bottom: 12px;
    }

    .MuiInputLabel-root, .MuiInput-input, .MuiInputBase-root {
      font-size: 13px;
      font-weight: 700;
      width: 400px;
    }

    .MuiInputLabel-root {
      margin-top: 14px;
      margin-left: -7px;

      &.MuiInputLabel-shrink {
        margin-top: 0px;
        font-size: 14px;
        font-weight: 700;
        transform: scale(1);
        margin-left: 7px;
      }
    }

    .MuiSelect-root {
      margin-left: 5px;
      padding: 1em 0;
    }


    .date-pickers {
      .filter-empty-label .MuiInputBase-root {
        color: #828282;
      }

      .MuiInputBase-root {
        margin-right: 4px;
        width: 150px;
      }
    }

    .filter-label.MuiInputLabel-root {
      margin-top: 15px;
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
    }

    .threat-areas-filter {
      margin-bottom: 10px;
    }
  }
}
