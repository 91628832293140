@import './src/css/variables';

.summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid rgba($heavymetal, 0.08);
    color: $heavymetal;
    font-size: 14px;
    padding: 24px;
    width: 100%;
    height: 160px;
    
    &:not(:last-child) {
        margin-right: 16px; 
    }

    &-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;

        &-border-bottom {
            border-bottom: 1px solid rgba($heavymetal, 0.08);
        }

        &-line {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            width: 42px;
        }
    }
}
