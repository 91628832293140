@import './src/css/variables';

.status-badge.badge {
    font-weight: 800;
    font-size: 11px;
    padding: 4px 8px;

    &-tag {
        color: rgba($heavymetal, 0.6);
        background-color: transparent;
        border: rgba($heavymetal, 0.1) 1px solid;
        margin-right: 4px;
    }

    &-dark {
        color: $heavymetal;
        background-color: rgba($heavymetal, 0.05);
        border: rgba($heavymetal, 0.25) 1px solid;
    }
    &-primary {
        color: #2f80ed;
        background-color: rgba(#2f80ed, 0.05);
        border: rgba(#2f80ed, 0.25) 1px solid;
    }
    &-warning {
        color: #ff8540;
        background-color: rgba(#ff8540, 0.05);
        border: rgba(#ff8540, 0.25) 1px solid;
    }
    &-danger {
        color: #eb5757;
        background-color: rgba(#eb5757, 0.05);
        border: rgba(#eb5757, 0.25) 1px solid;
    }
    &-success {
        color: $aspargus;
        background-color: rgba($aspargus, 0.05);
        border: rgba($aspargus, 0.25) 1px solid;
    }
}
