.header-full-width {
  width: 100%;
  text-align: center;

}

.subscription-plan, .subscription-plan-slider {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px 10px;
  width: 350px;
  min-height: 560px;
  height: 100%;

  &.subscription-plan-slider {
    max-width: 400px;
    margin: 0 auto;
    width: unset;
    min-height: unset;
  }

  h2 {
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;
    margin: 0 !important;
  }

  ul {
    font-size: 0.9em;
    padding-inline-start: 0px;
    padding: 0;

    li {
      position: relative;
      padding-left: 20px;
      list-style: none;
      margin: 10px 0;
      color: rgba($heavymetal, 0.8);
      font-size: 0.9em;

      strong {
        font-weight: 700;
      }
    }
  }

  .discount-banner {
    background-color: $aspargus;
    position: absolute;
    color: white;
    padding: 2px 10px;
    font-size: 0.8em;
    left: 66px;
    top: 0;
    width: 88px;
    border-radius: 3px;
    font-weight: 300;

    &:after {
      width: 0;
      height: 0;
      position: absolute;
      left: -6px;
      top: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid $aspargus;
      content: "";
    }
  }

  .user-count {
    margin: 20px 0;
    text-align: center;
    font-size: 0.8em;
    color: rgba($heavymetal, 0.8);

    select {
      font-size: 1em;
      height: 45px;
    }
  }

  .switch-text {
    position: relative;
  }

  .price {
    text-align: center;
    font-size: 1.6em;
    font-weight: 600;
    height: 70px;
  }

  .plan-description {
    height: 70px;
    margin: 0;
  }

  .btn {
    margin: 10px auto 10px auto;
    display: block;


  }

  .price-description {
    font-size: 0.8em;
    color: rgba($heavymetal, 0.6);
  }

  .currency {
    color: rgba($aspargus, 0.8);
    font-size: 0.8em;
    vertical-align: super;
  }

  .period {
    margin-left: 2px;
    color: rgba($aspargus, 0.8);
    font-size: 0.6em;

  }

  .description {
    text-align: center;
    color: $snowdrift;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 0.8em;
    padding: 5px 10px;
    background: $aspargus;
  }

  .description-text {
    text-align: right;
    color: rgba($heavymetal, 0.6);
    font-size: 0.7em;
    padding: 5px 0 0 0;
    margin-top: 20px;
    margin-bottom: 20px;

    strong {
      color: $aspargus;
    }
  }

  .current-plan {
    text-align: center;
    color: rgba($goldengrass, 0.6);
    font-size: 1em;
    font-weight: 700;
    margin-top: 20px;

    strong {
      color: $aspargus;
    }
  }
}

.subscription_plan-button_holder{
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}

.subscription-plan-slider {

  .current-arrow {
    background-image: url("/static/img/current-plan-arrow.png");
    position: absolute;
    left: 0px;
    top: -50px;
    width: 125px;
    height: 35px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom 5px;;
    background-size: 20px 20px;
    overflow: visible;

    &:after {
      color: $orange;
      margin-top: -18px;
      font-size: 11px;
      margin-left: 24px;
      content: "Current plan";
    }
  }

  ul {
    margin-block-start: 0;
    margin: 10px 0;
  }

  .switch {
    height: 40px;
    font-size: 1em;
    color: rgba($heavymetal, 0.6);
    margin: 10px 0;

    .selected {
      color: $aspargus;
      font-weight: 600;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
      color: $aspargus;

    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: $aspargus;

    }

    .MuiSwitch-colorSecondary.Mui-checked:hover {
      background-color: rgba($aspargus, 0.05);

    }
  }
}

.billing-cicle {
  cursor: pointer;
  font-weight: 700;
  padding: 4px 4px;
  margin: 10px 20px;

  &.selected {
    cursor: default;
    border-bottom: 4px solid $aspargus;
  }
}

.ex1.container {
  margin: auto;
  padding: 100px 0;
  min-height: 100vh;
}

.ex1-wrap {
  margin: auto;
  max-width: 400px;
  border-radius: 8px;
  background: #fff;
  padding: 32px;
}

.ex1-field {
  position: relative;
  margin-bottom: 32px;
}

.ex1-fields {
  display: flex;
  margin-left: -16px;
}

.ex1-fields .ex1-field {
  flex: 1;
  margin-left: 16px;
}

.ex1-label {
  font-size: 12px;
  font-weight: 500;
  color: #7b808c;
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
}

.ex1-input {
  width: 100%;
  display: block;
  background: transparent;
  border-radius: 0;
  border: none;
  padding: 4px 2px;
  border-width: 0;
  border-color: transparent;
  color: #333;
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
  transition: .2s;
  cursor: text;
  /* font-weight: inherit; */
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
}

.ex1-input::placeholder {
  color: transparent;
}

.ex1-input:focus::placeholder {
  color: #7b808c;
}

.ex1-input:focus ~ .ex1-label,
.ex1-input.focus ~ .ex1-label,
.ex1-input.val ~ .ex1-label,
.ex1-input.complete ~ .ex1-label,
.ex1-input.invalid ~ .ex1-label {
  font-size: 0.8rem;
  color: #7b808c;
  top: -1rem;
  left: 0;
}

.ex1-bar {
  position: relative;
  border-bottom: 0.0625rem solid #999;
  display: block;
}

.ex1-bar::before {
  content: '';
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #337ab7;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2;
}

.ex1-input:focus ~ .ex1-bar::before,
.ex1-input.focus ~ .ex1-bar::before {
  width: 100%;
  left: 0;
}

.ex1-button {
  background: #0950cc;
  background: #0c0ebd;
  color: #fff;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  display: block;
  width: 100%;
  letter-spacing: .5px;
  transition: .2s;
  cursor: pointer;
}

.ex1-button:hover,
.ex1-button:focus {
  background: #0641a7;
}

.ex1-button.submit {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWxvYWRlciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNTUiIGhlaWdodD0iNTUiIHZpZXdCb3g9IjAgMCA4MCA4MCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQwIDcyQzIyLjQgNzIgOCA1Ny42IDggNDBTMjIuNCA4IDQwIDhzMzIgMTQuNCAzMiAzMmMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJjMC0xNS40LTEyLjYtMjgtMjgtMjhTMTIgMjQuNiAxMiA0MHMxMi42IDI4IDI4IDI4YzEuMSAwIDIgLjkgMiAycy0uOSAyLTIgMnoiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgNDAgNDAiIHRvPSIzNjAgNDAgNDAiIGR1cj0iMC42cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48L3BhdGg+PC9zdmc+);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  color: transparent !important;
  transition-duration: 0s;
}

.ex1-wrap .token {
  color: #555;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.ex1-wrap .error {
  color: #e41029;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}
