@import "./src/css/variables";

/* Training header */
.training-wizard-header {

  .assessment-wizard-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;

    .edit-icon {
      margin-top: 3px;
      color: rgba($heavymetal, 0.6);
    }

    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      color: rgba($heavymetal, 0.6);
      letter-spacing: 0.04em;
      margin: 0px 0 5px 0;
      padding: 1px 4px;

      &::selection {
        background-color: rgba($aspargus, 0.2);
      }

      &:focus {
        box-shadow: 0 0 0 1px $aspargus;
        outline: none;
        border-radius: 2px;
      }

      &:hover:not(:focus) {
        box-shadow: 0 0 0 1px rgba($bitter, 0.2);
        outline: none;
        border-radius: 2px;
      }
    }

    p {
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 8px;
      color: rgba($heavymetal, 0.5);

    }
  }

  .right {
    position: absolute;
    right: 48px;
    font-weight: bold;
  }
}

.training-wizard-content {
  padding: 0 10px 10px 10px;

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      width: 50px;
      margin-top: 50px;
    }

    button {
      margin-top: 10px;
    }
  }

  .subheader {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    text-transform: none;
    padding: 10px 0;
  }

  h5 {
    margin: 10px 0 0 0;
  }

  .training-schedule-frequency {
    padding-top: 0;
  }

}

.MuiTextField-root {
  input {
    box-sizing: border-box;
    font-size: 14px;
    height: 40px;
    border-radius: 4px;
  }
}

.training-schedule {
  .training-schedule-options {
    padding: 10px 0;
  }
}

.name-black {
  color: rgba($heavymetal, 0.8) !important;
  text-align: center;
}

.actions-align-right {
  margin-left: auto;
}

.not-wide {
  max-width: 680px !important;
}

.previous-btn {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 800;
  color: $heavymetal;
  letter-spacing: 4%;
  cursor: pointer;
}

.assessment-wizard-info-message {
  background-color: #EDF5FE;
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 8px;
  z-index: 1000;
}

.modal__text {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 48px;
}

.modal__header {
  display: flex;
  padding: 0 48px;
  justify-content: space-between;
  align-items: center;
  height: 88px;

  .modal__heading {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }

  img {
    cursor: pointer;
  }
}
