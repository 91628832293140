.new-settings-block {
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;

  }

  .setting {
    font-size: 16px;
    font-weight: 600;

    .name {
      color: lighten($heavymetal, 30);
    }

    .help-questionmark {
      background-image: url('/static/img/icon-question.svg');
      background-color: lighten($heavymetal, 30);;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 7px auto;
    }

    .setting-list-item {
      padding: 8px;
      width: 100%;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      transition-property: all;
      background: #FCFCFC;
      border: 1px solid #F0EFEF;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 700;
      height: 40px;
      border-radius: 4px;
      text-overflow: ellipsis;
    }

    .text-thin {
      font-weight: 400;
    }
  }

  .company-dropdown {
    display: inline-flex;
    cursor: pointer;

    h3 {
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: -20px;
        top: 28px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid lighten($heavymetal, 10);
      }
    }

    h5 {
      color: lighten($heavymetal, 30);
      font-size: 12px;
      font-weight: 800;

      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: -20px;
        top: 25px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid lighten($heavymetal, 30);
      }
    }

    .dropdown-menu {
      button.small-dropdown-item {
        font-size: 12px;
        font-weight: 800;
      }
    }

  }

  .small-dropdown {
    cursor: pointer;
    float: right;
    margin-right: 20px;
  }

  .company-logo {
    max-height: 60px;
    max-width: 160px;
    position: absolute;
    right: 20px;
    cursor: pointer;
  }

  hr {
    margin: 0 20px;
    border: 0;
    border-top: 1px solid rgba(52, 53, 52, 0.1);

    &.padded {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .add-remove-btn {

    border-radius: 4px;
    height: 48px;
    width: 64px;
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;

    display: flex;
    cursor: pointer;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    background-color: rgb(220, 237, 230);
    color: #32be3f;

    &.selected {
      background-color: rgb(190, 190, 190);
      color: black;
      border-color: lighten($aspargus, 100);
    }
  }
}
