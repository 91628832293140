button {
  &:focus {
    outline: none;
    background: #f9f9f9;
  }
}

.btn {
  border: none;
  padding: 11px 32px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8rem;

  &.schedule {
    background: url("/static/img/icon-calendar2.svg") no-repeat;
    background-position: left 20px center;
  }

  &.big {
    padding: 0.7rem 1.4rem;
    font-size: 1.1rem;
  }

  &.plus {
    padding: 7px 18px;
    font-size: 1.1em;
  }

  &.btn-sm {
    padding: 0.25rem 0.5rem;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($aspargus, 0.25)
  }

  &.delete {
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("/static/img/icon-delete.svg");
    background-size: 14px auto;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: ease 0.2s all;
    display: flex;
    align-self: center;
    padding: 0 4px;
    transition: ease 0.2s all;

    &:hover {
      background-image: url("/static/img/icon-delete-over.svg");

    }

  }

  &.delete-bin {
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("/static/img/icon-delete-bin.svg");
    background-size: 14px auto;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: flex;
    align-self: center;
    padding: 0 4px;
    transition: ease 0.1s filter;
    position: relative;

    &:after {
      content: "Delete";
      position: absolute;
      bottom: 0;
      color: rgba(52, 53, 52, 0.5);
      width: 20px;
      left: -1px;
      bottom: -8px;
      font-size: 6px;
    }

    &:hover {
      filter: invert(20%) sepia(98%) saturate(3624%) hue-rotate(343deg) brightness(76%) contrast(90%);
    }

  }

  &.delete-bin-new {
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("/static/img/icon-remove-trash.svg");
    background-size: 14px auto;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: flex;
    align-self: center;
    padding: 0 4px;
    transition: ease 0.1s filter;
    position: relative;
  }

  &.edit {
    width: 20px;
    height: 20px;
    content: "";
    background-image: url("/static/img/icon-edit.svg");
    background-size: 14px auto;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: flex;
    align-self: center;
    padding: 0 4px;
    transition: ease 0.1s filter;
    position: relative;

    &:after {
      content: "Edit";
      position: absolute;
      bottom: 0;
      color: rgba(52, 53, 52, 0.5);
      width: 20px;
      left: -1px;
      bottom: -8px;
      font-size: 6px;
    }

    &:hover {
      filter: invert(20%) sepia(98%) saturate(3624%) hue-rotate(343deg) brightness(76%) contrast(90%);
    }

  }

  &.remind {
    font-size: 0.64em;
    text-align: center;
    height: 26px;
    color: #AAAAAA;
    padding-top: 15px;
    content: "";
    background-image: url("/static/img/icon-remind.svg");
    background-size: 12px auto;
    background-position: top center;
    background-repeat: no-repeat;
    transition: ease 0.2s all;

    &:hover {
      color: $aspargus;
      background-image: url("/static/img/icon-remind-over.svg");

    }

  }
}

.table.slim {
  .btn.delete-bin:after {
    left: 21px;
    bottom: 5px;
  }

}

.btn-primary {
  color: $snowdrift;
  background-color: $aspargus;
  border: 1px solid transparent;
  transition: ease 0.2s all;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  &.wide {
    padding-left: 50px;
    padding-right: 50px;
  }

  &:hover {
    background-color: darken($aspargus, 10) !important;
    border-color: darken($aspargus, 5) !important;
  }

  &:active {
    background-color: darken($aspargus, 20) !important;
    border-color: darken($aspargus, 15) !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($aspargus, 5) !important;
  }

  &.pay {
    margin-top: 20px;
    background-color: $aspargus;
  }

  @media screen and (max-width: 1200px) and(min-width: 1019px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.disabled {
    background: darken($desertstorm, 5);
    color: lighten($desertstorm, 5);
    border-color: lighten($desertstorm, 5);

    &:hover {
      background: darken($desertstorm, 5) !important;
    }
  }
}

.btn-secondary {
  color: $aspargus;
  background-color: rgba($aspargus, 0.1);
  border: 1px solid transparent;
  transition: ease 0.2s all;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;


  &:hover {
    background-color: darken($aspargus, 10);
    border: $aspargus 1px solid;
  }

  &.wide {
    padding-left: 50px;
    padding-right: 50px;;
  }

  &.pay {
    margin-top: 20px;
    background-color: $aspargus;
  }
}

.btn-link {
  color: lighten($heavymetal, 40);
  background-color: transparent;
  transition: ease 0.2s all;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $heavymetal;
  }

  &:focus, &:active {
    box-shadow: none;
  }

}


.btn-text {
  background: none !important;
  border: none;
  color: lighten($heavymetal, 10);
  font-weight: 100;
  transition: ease 0.2s all;
  width: none;
  box-shadow: none !important;

  &:hover {
    color: lighten($aspargus, 10);
    background: none !important;
    border: none;

    text-decoration: underline;
  }

  &.danger {
    &:hover {
      color: $shiraz;
    }
  }

  &.pay {
    margin-top: 20px;
    background-color: $aspargus;
  }

  &.secondary {
    color: lighten($snowdrift, 9) !important;

  }

  &.small {
    font-size: 0.8em;
  }
}


.btn-three-dots {
  padding: 20px 10px;
  border: none;
  outline: none;
  content: " ";
  width: 20px;
  height: 20px;
  left: 8px;
  top: 5px;
  background: url('/static/img/icon-three-dots.svg') no-repeat center center;
  background-size: 24px auto;
  width: 30px;

  &:hover, &:focus, &:active {
    background: url('/static/img/icon-three-dots.svg') no-repeat center center;
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;

  }
}

.dropdown-menu {
  border: none;
  box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.07);
  padding: 20px 10px;

  button {
    font-weight: 600;

    &.dropdown-item {
      background: none;

      a {
        //font-weight: 600;
        //color: $heavymetal;

      }

      &:hover {
        a {
          color: $aspargus;
        }

        color: $aspargus;
      }
    }
  }
}

.btn-top-right {
  position: absolute;
  right: 40px;
  top: 54px;
  font-size: 14px;

  .btn-text {
    cursor: pointer;
    color: $aspargus;
    font-weight: 600;
  }

  a {
    color: $aspargus;
    font-weight: 600;
  }
}


.copy-to-clipboard-btn {
  border: none;
  background: transparent;
  transition: ease 0.1s filter;
  position: relative;
  font-size: 8px;
  font-weight: 500;;
  color: rgba($heavymetal, 0.7);
  text-transform: uppercase;
  text-align: center;;

  &:after {
    content: "";
    position: absolute;
    top: -4px;
    width: 20px;
    height: 20px;
    background-image: url("/static/img/icon-copy.svg");
    background-size: 12px auto;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &:hover {
    filter: invert(45%) sepia(97%) saturate(348%) hue-rotate(103deg) brightness(95%) contrast(90%);
  }
}

.copy-to-clipboard-link {
  background: none;
  border: none;
  color: lighten($aspargus, 10);
  border-radius: 5px;
  margin-left: 10px;
  font-size: 0.8em;
  transition: ease 0.2s all;
}
