
.top-guide {

  h3 {
    text-align: left;
    font-weight: 600;
    font-size: 22px;
  }

  h5 {
    margin: 20px 0 10px 0;
    font-weight: 600;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    color: lighten($heavymetal, 20);
  }

  &.statistics {
    p {
      padding-right: 0;
    }

    .block {
      @media (min-width: 899px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      img {
        margin: 0 auto;
      }

    }
  }

  .block {
    background: white;
    padding: 20px 40px 40px 40px;
    position: relative;

    &.center {
      text-align: center;

      button {
        margin: 0 auto;
      }
    }

    .progress-arrow {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 60px;
      height: 60px;
      background-image: url("/static/img/arrow-up-green.svg");
      background-size: 34px auto;
      background-repeat: no-repeat;
      background-position: center center;

      &.up {
        background-image: url("/static/img/arrow-up-green.svg");
      }

      &.down {
        background-image: url("/static/img/arrow-down-red.svg");
      }


    }

    .question-mark {
      background: lighten($heavymetal, 70);
      border-radius: 50%;
      height: 24px;
      width: 24px;
      right: 14px;
      top: 14px;
      text-align: center;
      color: white;
      position: absolute;
      background-image: url('/static/img/icon-question.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 7px auto;
      margin-left: 7px;
      cursor: pointer;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .btn-action {
        color: $aspargus;
        align-self: center;
        font-size: 0.8em;
        font-weight: 600;
      }
    }

    .score {
      font-size: 50px;
      line-height: 55px;
      font-weight: 700;
      margin-top: 40px;

      span {
        font-weight: 100;
        opacity: 0.2;
        font-size: 0.6em;
      }

      p {
        margin-bottom: 30px;
      }
    }

    .cta {
      color: $aspargus;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.01em;
      text-align: left;
      margin-top: 40px;
      transition: ease 0.2s all;
      cursor: pointer;

      &:hover {
        color: darken($aspargus, 10);
      }
    }
  }

  .number-field {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #F5F3F3;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 20px;
    align-items: center;

    .right {
      color: $aspargus;
      font-weight: 700;
      font-size: 20px;

    }
  }

  .alert {
    background: white;
    padding: 20px;
    text-align: left;
    position: relative;

    p {
      margin: 0;

    }

    &.success {
      padding-left: 70px;

      &:before {
        display: flex;
        content: "";
        position: absolute;
        left: 5px;
        top: 0;
        width: 60px;
        height: 100%;
        background-image: url("/static/img/icon-checkmark.svg");
        background-size: 34px auto;
        background-repeat: no-repeat;
        background-position: center center;

      }
    }

    .close {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.system-alert {
  &.alert {
    text-align: center;
    margin-bottom: 0;
    border: 0;

    &.header {
      border-radius: 0;
      margin-top: -1px;
    }

    &.alert-success {
      border: none !important;
      background: $aspargus;
      color: white;

      a {
        color: white;
        padding: 4px 10px;
        border-radius: 3px;
        font-weight: 100;
        background: $aspargus;
        border: none;
      }
    }

  }
}
