@import './src/css/variables';

.dashboard-sidebar {
    max-width: 350px;
}

.dashboard-schedule {
    padding: 16px;
    overflow: scroll;
    height: 304px;
    width: 100%;

    // hide scrollbar in different browsers
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* all other browsers */
    }

    &-group {
        margin-bottom: 8px;
    }

    .event {
        display: flex;
        font-size: 14px;
        padding: 4px 0;
        align-items: center;

        &-wrapper {
            border-left: rgba($heavymetal, 0.1) 1px solid;
            margin-left: 8px;
            height: 48px;
            display: flex;
            align-items: center;
        }

        &-empty {
            width: 48px;
        }

        &-date {
            background-color: $white;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            color: $heavymetal;
            width: 48px;
            height: 48px;
            align-items: center;
            justify-content: center;
            font-weight: 700;

            &-today {
                background-color: rgba($aspargus, 0.1);
                color: $aspargus;
            }

            &-selected {
                background-color: $aspargus;
                color: white;
            }

            &-day-of-week {
                padding-top: 7px;
                line-height: 8px;
                font-size: 10px;
                font-weight: 800;
                letter-spacing: 2%;
            }
        }

        &-details {
            color: rgba($heavymetal, 0.6);
            font-size: 11px;
            margin-left: 12px;

            h3 {
                font-size: 14px;
                font-weight: 700;
                margin: 0 0 4px 0;
                color: $heavymetal;
                cursor: pointer;
            }
        }

        &-unscheduled h3 {
            margin: 0 0 0 12px;
            font-size: 14px;
            color: rgba($heavymetal, 0.6);
            font-weight: 600;
        }

        &-disabled {
            opacity: 0.5;
        }
    }
}

.dashboard-calendar {
    margin-top: -12px;

    &-heading {
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.4;
        padding-top: 17px;
        padding-bottom: 17px;
        align-self: flex-start;
        padding-left: 24px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }

    &-empty {
        font-size: 14px;
        color: rgba($heavymetal, 0.6);
        text-align: center;
        padding: 0px 16px 32px 16px;
        max-width: 208px;
    }
}
