@import "./src/css/variables";

.question-select{ 
    .modalHeader {
    align-items: baseline;
    padding-top: 28px !important;
    h3 {
      margin-right: 32px;
      font-size: 24px;
      font-weight: 700;
    }
    .MuiFormControl-root {
      margin-right: 16px;
      width: 200px;
    }
    .MuiSelect-select {
      &:focus {
        background: none;
      }
    }
    .MuiInputLabel-shrink {
      // margin-left: 0px;
      // top: -10px;
      background: white;
      // padding: 3px;
    }
    
  }
  .modalContent {
    // margin-left: 20px;
    h4 {
      margin-top: 20px;
      font-weight: 700;
      font-size: 18px;
    }
    .subjects-list {
      .subjectItem {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 65px;
        width: 95%;

        .question-add-remove {
          margin-right: 3%;
          cursor: pointer;
        }
        .description {
          width: 90%;
          margin-right: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;


          h3 {
            font-weight: 700;
            margin: 0;
            font-size: 14px;
            line-height: 24px;
          }
          p {
            font-size: 13px;
            margin: 0;
          }
        }
        .preview {
          width: 120px;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          padding: 10px;
          cursor: pointer;
        }

      }
    }
  }

  .sum {
    font-size: 13px;
    font-weight: 600;
  }
}

