@import "./src/css/variables";

.assessment-list-header {
  display: flex;
  justify-content: space-between;
}

.assessment-sort-by {
  display: flex;
  align-items: center;
}

.nav-with-sort {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 24px;
}

.background-loader {
  position: absolute;
  top: -10px;
  left: -10px;
}

.assessment-filter-input, .btns-right-align .MuiInputBase-root {
  display: flex;
  width: 240px;
  border-radius: 4px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 700;
  background: transparent;
  margin-left: 16px;
}

.assessment-filter-input {
  border: rgba($heavymetal, 0.05) solid 1px;
}

.assessment-list-header .btns-right-align {
  .default-color {
    color: rgba($heavymetal, 0.6) !important;
  }

  .MuiInputBase-root {
    margin-top: 0px;
  }

  .MuiSvgIcon-root {
    cursor: pointer;
  }

  flex-grow: 1.5;
  display: flex;
  justify-content: space-between;
  margin-left: 150px;
}

.no-assessments-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;

  .stats {
    text-align: center;
  }
}


.circle-progress-cropped {
  width: 50px;
  height: 30px;
  overflow: hidden;
}

.circle-progress-small-font {
  font-size: 12px !important;
  font-weight: 800;
}
