.company-details {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 2px;
  margin-bottom: 30px;
  box-shadow: 3px 4px 30px -8px rgba(59, 55, 55, 0.18);
  font-size: 14px;

  &.expired {
    background: lighten($snowdrift, 2);
    color: rgba($heavymetal, 0.3);

    h3, p {
    }

  }

  .name {
    font-weight: 600;
    font-size: 1.5rem;
    display: inline;
  }

  .status {
    position: absolute;
    right: 20px;
    color: $aspargus;
    top: 10px;
    padding: 16px;
    display: flex;
    z-index: 1;

    &.expired {
      color: rgba($heavymetal, 0.6);
    }
  }

  .edit-icon {
    margin-left: 20px;
    cursor: pointer;
    color: $aspargus;
    display: inline;
  }


}

.alert.alert-in-block {
  margin: 0 -30px -20px -30px;
}


.action-link {
  font-size: 1.1em;
  font-weight: 600;
  color: $aspargus;
  cursor: pointer;
  text-align: right;

  &:hover {
    color: inherit;
  }
}

.company-logo-drop-zone {
  width: 100%;
  padding: 10%;
  border: thin dashed #DDDDDD;
  border-radius: 4px;
  background-color: rgba(#F0EFEF, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.drop_zone_title {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
}

.drop_zone_description {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.drop_zone_tooltip {
  margin-left: 5px;
}

.company-logo-drop-zone-text {
  font-size: 14px;
  margin-top: 5px;
}

.company-logo-display {
  height: 100%;
}

.remove-logo-link {
  color: $shiraz;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 5px;

  .remove-logo-icon {
    background: url('/static/img/icon-remove-circle.svg');
    height: 12px;
    width: 12px;
    top: 8px;
    right: 2px;
    display: block;
    position: relative;
    float: left;
  }
}

.select-color-modal {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 10px;
  }

  .text {
    color: #616263;
    font-size: 14px;
  }

  .picker-color {
    width: 64px;
    height: 48px;
    border-radius: 2px;
  }

  .picker-swatch {
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
  }

  .picker-popover {
    position: absolute;
    z-index: 2;
    top: 60px;
    left: -208px;
  }

  .picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.company-appearance {
  position: relative;
  padding: 30px;
  background: white;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: 3px 4px 30px -8px rgba(59, 55, 55, 0.18);
  font-size: 14px;

  .actions {
    padding-top: 20px;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 10px;
  }

  .subtitle {
    color: #616263;
  }

  .current {
    position: absolute;
    right: 0;
  }

  .change-current {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .image {
    max-height: 50px;
    max-width: 100%;
    cursor: pointer;
  }

  .color-row {
    display: flex;
  }

  .color-box {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    cursor: pointer;
  }

  .color-box-separator {
    width: 20px;
  }

}

.sync-preview-action-add {
  color: #3dcc4a;
}

.sync-preview-action-skip {
  color: #D5801C;
}

.sync-preview-action-remove {
  color: #bd4147;
}

