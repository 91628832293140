
.header-title-table {
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 5px 20px;
  background: lighten($desertstorm, 4);
  border-radius: 5px;

  .title {
    padding-top: 4px;
    font-size: 0.6em;
    opacity: 0.5;
  }

  .text {
    font-size: 0.8em;
  }
}

.table-white-bg {
  background: white;
  padding: 5px 20px;
  border-radius: 5px;
}

.table-dark-bg {
  background: $heavymetal;
  padding: 5px 20px;
  border-radius: 5px;
  color: $snowdrift;
}

.table-footer {
  text-align: right;
  padding: 10px;
  font-size: 0.8em;
}

.simple-clear-table {
  width: 100%;
  margin: 20px 0;
  font-size: 0.8em;

  td {
    padding: 5px 0;
    border-bottom: 1px solid rgba($heavymetal, 0.02);
  }

  .last-column {
    width: 40%;
  }
}

.simple-table {
  width: 100%;
  margin: 20px 0;
  font-size: 0.9em;

  &.white {
    background: white;
  }

  &.no-margin {
    margin: 0;
  }

  th {
    border-bottom: 2px solid #E7E5E1;
    margin-bottom: 4px;

  }

  .list-line {
    display: flex;
  }

  td {
    border-bottom: 1px solid #E7E5E1;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: rgb(245, 245, 245);
  }


  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #FFFFFF;
    padding: 3px 5px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 0.9em;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(221, 218, 218, 0.6);
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-center {
    text-align: center;
  }

  tr.invalid {
    color: gray;
  }
}

.table2 {
  width: 100%;
  margin: 20px 0;
  font-size: 0.9em;

  &.white {
    background: white;
  }

  th {
    border-bottom: 2px solid #E7E5E1;
    margin-bottom: 4px;

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-right: none;
    }
  }

  .list-line {
    display: flex;
  }

  td {
    border-bottom: 1px solid #E7E5E1;
    line-height: 34px;
  }

  tr:first-child {
    border-top: none;
  }

  tr:last-child {
    border-bottom: none;
  }

  tr:nth-child(odd) td {
    background: rgb(245, 245, 245);
  }


  tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
  }

  td {
    background: #FFFFFF;
    padding: 3px 5px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 0.9em;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(221, 218, 218, 0.6);
  }

  td:last-child {
    border-right: 0px;
  }

  th.text-center {
    text-align: center;
  }
}

.react-table {
  tfoot {
    display: flex;

    div {
      white-space: nowrap;
      line-height: 1em;;
    }

    button {
      border-radius: 4px;
      border: 1px solid #F1F0F0;
      padding: 5px;
      margin-left: 2px;
      margin-top: 7px;
      margin-bottom: 7px;
      margin-right: 20px;
      outline: 0;
      background: #FCFCFC;
      position: relative;

    }

    input {
      border-radius: 4px;
      margin: 0 10px 0 0;
      max-width: 40px;;
      border: 1px solid #ccc;
    }

    select {
      padding: 4px;
    }
  }

  .pagination {
    border-radius: 5px;
    font-size: 0.8em;
    align-items: center;
  }

}

.table {
  border-radius: 5px;
  background: white;
  margin-top: 20px;
  padding: 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  border: none !important;

  &.vertical-center {
    td {
      display: table-cell;
      vertical-align: middle;
    }
  }

  @media (max-width: 899px) {
    display: block;
    padding: 0px;
  }

  &.slim {
    tr {
      td {
        padding: 0 10px;
        font-weight: 100;

        a {
          font-weight: 600;
        }
      }
    }
  }

  thead {
    border-bottom: 1px solid $desertstorm;
    padding-bottom: 14px;

    .title_left_padding {
      padding-left: 50px;
    }

  }

  td {
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    float: left;
    margin-right: 10px;

    img {
      width: 20px;
    }
  }

  .text {
    color: darken($desertstorm, 30);
    font-size: 0.8em;
    font-weight: 100;
  }

  .text-thin {
    font-weight: 100;
  }

  tr {
    border-bottom: 1px solid lighten($desertstorm, 4);
    padding: 2px 4px;
    font-size: 0.9em;

    &:last-child {
      border-bottom: 1px solid $desertstorm;
    }

    &:nth-child(even) {
      background-color: #fafafa;

    }

    &.reactable-filterer {
      &:hover {
        background: transparent;
      }
    }

    &:hover {
      background-color: #efefef;
    }


    a {
      transition: ease 0.2s color;

      &:hover {
        color: $aspargus;
        text-decoration: underline;
      }
    }

    .small {
      font-size: 0.8em;
    }

    .expired {
      color: gray;
    }
  }

  td:nth-child(n) {
    padding-left: 5px;
  }
}

.progress {
  background-color: #EDEDED;
}


.manager-user-table {
  background: white;
  padding: 20px 10px;
  overflow-x: scroll;

  th {

  }

  .user-title {
    font-size: 0.9em;
    color: darken($desertstorm, 40);
    font-weight: 100;

    &:hover {
      text-decoration: underline;
    }

    a {
      color: $heavymetal;
      text-decoration: none;
      font-weight: 600;
    }
  }

  .delete {
    color: $shiraz;
    font-size: 0.8em;

    .btn {

    }
  }

  table {
    th {
      border-bottom: 1px solid rgba($aspargus, 0.2);
      border-top: 1px solid rgba($aspargus, 0.2);
      margin-bottom: 4px;
      border-top: none;
      background: lighten($snowdrift, 10)

    }

    .list-line {
      display: flex;
    }

    .center {
      text-align: center;
    }

    td {
      padding: 0.4rem;
      border-bottom: 1px solid lighten($desertstorm, 5);
      display: table-cell;
      vertical-align: middle;

      &.subject-title {
        font-size: 0.9em;
      }

      &.text {
        font-size: 0.9em;

      }

    }
  }


  .user-title {
    padding: 4px;

    .icon {
      padding-right: 10px;
      float: left;

      img {
        width: 24px;
      }
    }
  }

  .tableHeader {
    justify-content: flex-end;
    align-content: flex-start;
    /* Campaign list */
    .date {
      margin-top: 20px;
      font-size: 0.8em;
      opacity: 0.6;
    }

    .list-line {
      overflow: hidden;
      background: white;
      font-size: 0.9em;
      border-radius: 3px;
      padding: 0.75rem;
      width: 100%;

      &.sideways {
        overflow: visible;
        min-height: 100px;
        background: none;
        margin: 0;

        .col {
          width: 14px;
          transform: rotate(-86deg) translateX(-34px);
          padding: 0;
          left: 10px;
          text-indent: -6px;
        }
      }

      .col {
        justify-content: flex-start;
        text-align: left;
        font-size: 13px;
        opacity: 0.6;;
        width: 25px;
        white-space: nowrap;
        left: 10px;
      }

      .percentage {
        color: $aspargus;
      }

      .icon {

        img {
          width: 20px;
        }
      }
    }

  }

  .col {
    width: 25px;
    min-height: 25px;

    .not-checked {
      width: 14px;
    }

    img {
      width: 20px;
      height: auto;
    }
  }
}

img.check-mark {
  width: 14px;
  height: 14px;
}

img.view-mark {
  width: 22px;
  height: 22px;
}

img.check-mark.not-checked {
  width: 10px;
  height: 10px;
}


/* table buttons */
table {
  .btn-sm {
    padding: 4px 10px;
    font-size: 0.8em;
  }

  .badge {
    padding: 0.65em 1.4em;
    background-color: lighten($desertstorm, 5);
  }
}

table {
  text-align: left;

  &.table tr {
    border: none;
    border-bottom: 0.01em solid #eee;

    td {
      padding: 12px 0;
      border: 0;
      font-size: 14px;
      font-weight: 600;

      &.title {
        font-weight: 600;
      }

      &.progress {
        border: 0;
        margin-top: 17px;
        height: 0.5rem;
        padding: 0;
        background: #F0EFEF;
        border-radius: 4px;
        display: block;

        .progress-bar {
          border-radius: 4px;
          height: 8px;
        }

      }

      &.number {
        font-weight: 600;
        text-align: right;
      }

      &.center {
        font-weight: 600;
        text-align: center;
      }
    }
  }

  thead {
    border-bottom: 0;
    border-top: 0;

    th {
      color: #1D1E1D;
      font-size: 14px;
      font-weight: 600;
      border: none !important;
      padding-left: 3px;

      &:hover, &:focus {
        outline: none;
      }

      &.center {
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
