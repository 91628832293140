@import "./src/css/variables";

.time-of-delivery {
  color: rgba($heavymetal, 0.5);
  font-size: 12px;
  font-weight: 600;
}

.summary-introMessage {
  font-weight: initial;
  text-transform: initial;
  margin-top: 10px;
}
