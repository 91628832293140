
.MuiSlider-root {
  &.mui-slider-custom {
    max-width: 500px;
    color: $aspargus;

    .MuiSlider-thumb {
      border: 4px solid $aspargus;
      background-color: white;
      width: 16px;
      height: 16px;

      &:hover, &:active {
        box-shadow: 0px 0px 0px 8px rgba($aspargus, 0.16)
      }

      &.MuiSlider-rail-active {
        box-shadow: 0px 0px 0px 12px rgba($aspargus, 0.16)
      }

      &.MuiSlider-active {
        box-shadow: 0px 0px 0px 12px rgba($aspargus, 0.16)
      }
    }

    .MuiSlider-rail {
      height: 6px;
      background-color: #E8E8E8;
    }

    .MuiSlider-track {
      height: 6px;
    }

    .MuiSlider-mark {
      height: 6px;
      background-color: white;
    }
  }

}

.MuiButtonBase-root {
  &.MuiIconButton-colorSecondary {
    color: $shiraz;
    opacity: 0.7;
    transition: ease all 0.2s;

    &:hover {
      opacity: 1;
      background: none;
    }
  }
}
