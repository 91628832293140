@import "./src/css/variables";

.header-description {
  font-size: 12px;
  font-weight: 600;
  color: rgba($heavymetal, 0.6);
  display: flex;
  align-items: center;
}

.assessment-view-header {
  padding: 20px;
  align-items: center;
  padding-right: 25px;

  h3 {
    font-weight: 800;
  }
}

.additional-text {
  font-size: 13px;
  font-weight: 400;
  color: rgba($heavymetal, 0.8);
}

// .assessment-show-results-for {
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   padding: 20px 0 8px 0;

//   p {
//     margin: 0;
//     margin-right: 8px;
//     font-weight: 600;
//   }

//   .MuiOutlinedInput-root {
//     margin-top: 0;
//     background-color: transparent;
//   }

//   .MuiInputBase-root {
//     margin-bottom: 0 !important;

//     &.MuiFormControl-root {
//       width: 170px;
//     }
//   }

//   .MuiSelect-outlined, .MuiSelect-select:focus {
//     border: 1px solid rgba($heavymetal, 0.1);
//     border-radius: 4px;
//     background-color: transparent;
//   }

// }

.no-margin-top {
  margin-top: 0;
}

.assessment-view-review-answer {
  margin-top: -10px;
  padding: 8px 16px !important;
}

.show-more-arrow {
  border-radius: 100%;
  margin: 0 5px;
  border: 1px solid black;
  padding: 2px;
  max-width: 32px;
  max-height: 32px;
  cursor: pointer;

  &:hover {
    background-color: rgba($heavymetal, 0.05);
  }


}

.horizontal-scroll-bar {
  overflow: auto;
  scroll-behavior: smooth;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.assessment-view-subheader {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0;

  span {
    color: rgba($heavymetal, 0.4);
    margin-left: 5px;
  }
}
