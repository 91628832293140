@import "./src/css/variables";

.assessment-reminder-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;

  .reminder-row-count {
    font-size: 13px;
    font-weight: 700;
    color: rgba($heavymetal, 0.5);
    margin-right: 16px;
  }

  .reminder-training-input {
    color: black;
    margin-right: 16px;
    font-weight: 700;
    font-size: 13px;
    min-width: 100px;
    max-width: 100px;

    &.wide-input {
      min-width: 180px;
    }
  }
}

.reminder-row-label {
  margin: 20px 0 10px 0 !important;
}


.add-new-reminder {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}


