.assessment-wizard-steps {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 312px;
  margin-right: 48px;

  @media only screen and (min-width: 1440px) {
    margin-right: 0;
  }
}

// .sidebar-extra-info {
//     width: 220px;
//     background-color: rgba($heavymetal, 0.05);
//     border-radius: 8px;
//     margin-left: 30px;
//     font-size: 12px;
//     font-weight: 600;
//     padding: 15px;
// }
