@import './src/css/variables';

.integration-card-header {
    display: flex;
    justify-content: space-between;
    min-width: 100%;
    align-items: center;

    img {
        height: 40px;
        max-width: 90px;
    }
}

.integration-detail-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    background-color: white;
    padding: 30px;
    margin-top: 10px;

    &.align-left {
        align-items: flex-start;
    }

    h2 {
        font-size: 16px;
        font-weight: 600;
    }

    h3 {
        padding: 0;
        line-height: initial;
        margin: 20px 0 5px 0;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        color: rgba($heavymetal, 0.6);

        &.margin-bottom {
            margin-bottom: 20px;
        }
    }

    img {
        width: 64px;
    }

    .header-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .integration-data {
        color: rgba($heavymetal, 0.6);
        font-size: 14px;
        font-weight: 700;
    }

    .integration-status {
        font-size: 14px;
        font-weight: 700;

        .success {
            color: $aspargus;
        }
    }

    .section {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .section-heading {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .button-with-description {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiFormControlLabel-label {
            font-size: 12px;
            font-family: 'Nunito Sans', sans-serif;
            color: rgba($heavymetal, 0.6);
            font-weight: 400;
        }
    }
}

.integration-detail-sidebar {
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 20px 30px;
    font-size: 14px;
    margin-top: 10px;

    p {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
    }
}
