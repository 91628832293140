#frm {
  width: 200px;
  margin: auto;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

#modal.hidden {
  display: none;
}

.bt-modal-frame {
  height: 480px;
  width: 440px;
  margin: auto;
  background-color: #eee;
  z-index: 2;
  border-radius: 6px;
}

.bt-modal-body {
  height: 400px;
  margin: 0 20px;
  background-color: white;
  border: 1px solid lightgray;
}

.bt-modal-header, .bt-modal-footer {
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.bt-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.8;
}

.cb-style {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  height: 38px;
  border-radius: 4px;
}
