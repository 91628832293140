@import "./src/css/variables";

.assessment-list-table {
  width: 100%;

  thead th {
    color: rgba($heavymetal, 0.6);
    font-size: 12px;
    padding: 12px 6px 6px 5px;

    &:nth-child(1) {
      padding-left: 12px;
    }
  }

  .assessment-row {
    background-color: transparent !important;
    transition: ease 0.2s all;
    border: 0px;
    border-top: 1px solid #efefef !important;
    cursor: pointer;

    &:hover {
      transform: scale(1.005) translateY(-1px);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
      z-index: 100;
      position: relative;
      background-color: white !important;
      border-radius: 4px;
      border: 0px;

      .assessment-actions-hover {
        opacity: 1;
      }

      + .assessment-row {
        border: 0px;
      }
    }

    td {
      vertical-align: middle;
      color: rgba($heavymetal, 0.6);
      font-size: 12px;
      padding-right: 0.5em;
      border-radius: 4px;

      &:nth-child(1) {
        padding-left: 12px;
      }

      .assessment-actions-hover {
        display: flex;
        opacity: 0;
      }
    }
  }
}
