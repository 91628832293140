button:focus {
  background: transparent;
}


.MuiFormControl-root .MuiInputBase-root {
  min-width: 200px
}

.ongoing-text {
  font-size: 16px;
  font-weight: 600;
}

.extra-message {
  margin-top: 1em;
  padding: 2em;
  align-items: center;
  justify-content: space-evenly !important;
}

.training-schedule {
  .checkbox-with-tooltip {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .MuiFormControlLabel-root {
    margin: 0;
    margin-right: 5px;
  }
}
