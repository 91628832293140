@import "./src/css/variables";

.questions-order {
  &.dragZone {
    .MuiTable-root {
      border-collapse: collapse;

      .dragItem {
        .MuiTableCell-root {
          border-bottom: 1px solid rgba($heavymetal, 0.05);
          padding: 0;
          vertical-align: middle;

          .subject-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $heavymetal;
          }
        }

        // action show on hover
        &:hover {
          > .action {
            opacity: 1;
          }
        }

        .action {
          opacity: 0;

          img {
            width: 20px;
          }
        }

        td {
          transition: ease 0.2s all;
          opacity: 1;

          &:hover {
            td:nth-child(3), td:nth-child(4) {
              opacity: 1;
            }
          }
        }

        &:active {
          td {
            border-top: none;
          }

          // hide when dragging
          td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6) {
            opacity: 0;
            z-index: 9990;
          }
        }
      }
    }
  }

  tr {
    th {
      color: #5E6C75;
    }

    td {
      padding: 12px 0 !important;
    }
  }

  p {
    margin: 0;
    font-size: 13px;
  }

}

.underline {
  text-decoration: underline;
}

.assessment-drag {
  min-width: auto !important;
  width: auto !important;
  padding-right: 24px;
}

