.user-status {
  .step-info {
    background: white;
    margin: 10px;
    padding: 30px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

    img {
      align-self: center;
      margin-bottom: 20px;
      width: 80px;
      height: 80px;
    }

    &.step1 {
      img {
        align-self: center;
        width: 60px;
      }
    }

    h4 {
      padding: 10px 0;
    }

    p {
      font-size: 0.8em;
      margin: 10px 0;
    }

    .btn {
      justify-self: flex-end;
      margin-top: 20px;
    }

    &.step1 {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-right: 1px solid $desertstorm;
    }

    &.step3 {
      border-left: 1px solid $desertstorm;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    span {
      font-size: 0.8em;
      opacity: 0.6;
    }

  }
}


.list-external-badge {
  background: $gray-100;
  border-radius: 4px;
  padding: 2px 4px;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}
