@import "./src/css/variables";

.modalHeader, .modalFooter {
  display: flex;
  justify-content: space-around;

  .action {
    margin-left: 10px;
    margin-top: -13px;
    flex: 4;

    input {
      padding-left: 5px;
    }
  }

  p {
    margin-right: 10px;
  }
}

.modalSearch {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .MuiFormControl-root {
    width: 70%;

    .MuiInputBase-root {
      height: 46px;
      line-height: 36px;

      input {
        padding-left: 8px;
      }
    }
  }
}

.select-users {
  padding-top: 32px;

  table {
    width: 100%;
    height: 38vh;

    thead {
      tr {
        border-top: 0px;

        &:hover {
          transform: none;
          box-shadow: none;
          border-radius: 4px;
          cursor: default;
        }
      }

      th {
        font-size: 14px;
        color: rgba($heavymetal, 0.6);
        padding: 3px;

        &:nth-child(1) {
          min-width: 430px;
        }
      }

    }

    tr {
      transition: ease 0.2s box-shadow;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid transparent;

      td {
        padding: 4px 0;
        margin: 0;
        font-size: 20px;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5;
        color: rgba($heavymetal, 0.6);
        white-space: normal;

        &:nth-child(1) {
          min-width: 430px;
          color: #4A4B4A;
          font-weight: 800;
          font-size: 23px;
          font-size: 16px;
          line-height: 24px;
        }

        &:nth-child(2) {
          margin-right: auto;
        }

        .MuiTypography-root {
          font-weight: 700;
          line-height: 24px;
          color: rgba($heavymetal, 0.8);
        }

        span {
          .MuiTypography-root {
            pointer-events: none;
          }
        }

        label {
          margin: 0;
          padding: 0;
        }

      }

      &:hover {
        transform: scale(1.005) translateY(-1px);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
        border-radius: 4px;
        z-index: 1;
        position: relative;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;


        + tr {
          border: none;
        }
      }
    }

    .MuiTableCell-root {
      border: none;
    }

    .show-recipients {
      padding: 10px 0px 10px 40px;
      background-color: rgba($heavymetal, 0.05);
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 25px;

      &-link {
        margin-right: 20px;
      }
    }
  }
}

.modalFooter {
  p {
    font-size: 13px;
  }

  strong {
    p {
      font-weight: 600;
    }
  }

  .MuiTypography-colorPrimary {
    font-weight: 800;
  }
}

.search-modal {
  height: 70vh !important;
}

