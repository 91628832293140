@import "./src/css/variables";

.key-behaviour-coverage {
  padding: 40px 0px;
  width: 284px;
  margin-left: 48px;
  margin-right: 48px;

  @media only screen and (min-width: 1440px) {
    margin-left: 0;
  }

  h4 {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
  }

  h5 {
    font-weight: 800;
    font-size: 12x;
    line-height: 14px;
  }

  p {
    font-weight: 400;
    color: rgba($heavymetal, 0.6);
    font-size: 12px;
  }

  .progress-list {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 12px 0;

      .title {
        flex: 70;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: rgba($heavymetal, 0.6);
        margin-right: 1em;
      }

      .graphics {
        flex: 30;
        display: flex;
        align-items: center;

        .progress {
          width: 80%;
          margin-top: 6px;
          border-radius: 4px;

          &.MuiLinearProgress-colorPrimary {
            background: rgba($aspargus, 0.2);

            .MuiLinearProgress-bar {
              background: rgba($aspargus, 1);
            }
          }

          &.MuiLinearProgress-colorSecondary {
            background: rgba($orange, 0.2);

            .MuiLinearProgress-bar {
              background: rgba($orange, 1);
            }
          }
        }

        p {
          font-size: 10px;
          font-weight: 600;
          margin: 0;
        }

        .icon {
          margin-left: 7px;
          z-index: 20;

          .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
          }

          .MuiSvgIcon-colorPrimary {
            color: $aspargus;
          }

          .MuiSvgIcon-colorSecondary {
            color: $orange;
          }
        }

      }
    }
  }
}
