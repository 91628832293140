.ppv {

  .embed-holder {
    border: 2px #ccc dashed;
    padding: 10px;
    margin: 50px auto 20px auto;
    width: 720px;
  }

  .embed {
    position: relative;
    max-width: 720px;
    background: #E7E5E1;
  }

  .description {
    padding: 10px;
    margin: 0px auto 20px auto;
    width: 720px;

  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  body {
    font-family: 'Nunito Sans', sans-serif;
    color: #343534;
  }

  .language-chooser {
    border-radius: 5px;
    padding: 5px 10px;
    display: block;
    position: relative;
    margin-top: 5px;
    z-index: 10000;
  }

  .selected {
    background: white;
    width: 140px;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .nav-item-dropdown {
    min-width: 140px;
    display: none;
    opacity: 1;
    background: white;
    border-radius: 5px;
    overflow: hidden;

  }

  .language-chooser .nav-item-dropdown {
  }

  .language-chooser:hover .nav-item-dropdown {
    position: absolute;
    display: block;
    opacity: 1;
    bottom: 46px;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
    left: 1px;
  }

  .flag {
    width: 30px;
    height: 30px;
    padding: 7px 5px 5px 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 22px auto;
    position: absolute;
    right: 0;
    font-size: 14px;
  }

  .nav-item-dropdown a {
    text-decoration: none;
    color: #343534;
  }

  .flag:hover {
    background-color: #eeeeee;
  }

  .flag {
    &.english {
      background-image: url("/static/img/flags/english.svg");
    }
  }

  .flag.english-gb {
    background-image: url("/static/img/flags/english_uk.svg");
  }

  .flag.spanish {
    background-image: url("/static/img/flags/spanish.svg");
  }

  .flag.italian {
    background-image: url("/static/img/flags/italian.svg");
  }

  .flag.french {
    background-image: url("/static/img/flags/france.svg");
  }

  .flag.deutsch {
    background-image: url("/static/img/flags/deutch.svg");
  }

  .flag.icelandic {
    background-image: url("/static/img/flags/icelandic.svg");
  }

  .flag.danish {
    background-image: url("/static/img/flags/danish.svg");
  }

  .flag.swedish {
    background-image: url("/static/img/flags/swedish.svg");
  }

  .flag.norwegian {
    background-image: url("/static/img/flags/norwegian.svg");
  }

  .brand {
    position: absolute;
    float: right;
    right: 5px;
    bottom: 14px;
    width: 90px;
  }

  .brand img {
    width: 90px;
  }

  .embed-code {
    background: #343534;
    color: white;
    width: 720px;
    border-radius: 5px;
    padding: 20px;
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    margin: 0 auto;
  }

  .embed-code xmp {
    white-space: pre-wrap;
    margin: 0;
    text-align: left;
  }


  .languages {
    background: $snowdrift;
    display: flex;
    align-items: flex-start;

    ul {
      list-style: none;
      padding-inline-start: 0px;
      text-align: left;
      padding: 10px;

      li {
        line-height: 30px;
      }

      input {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it
        z-index: 100;
        width: 100%;
        height: 100%;

        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
        }

        // Box.
        & + label:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: white;
        }

        // Box hover
        &:hover + label:before {
          background: #6C9D55;
        }

        // Box focus
        &:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        // Box checked
        &:checked + label:before {
          background: #6C9D55;
        }

        // Disabled state label.
        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 12px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
          transform: rotate(45deg);
        }
      }

      input:checked + ul > li {
        color: #6C9D55;
      }

    }
  }
}

.ppv-status {

  &.disabled {
    opacity: 0.5;
  }
}

.ppv-thumb {
  max-width: 72px;
  margin-right: 10px;
}
