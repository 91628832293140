.pre-col {
  max-width: 270px;
}

.acl-target-prefix {
  font-family: monospace;
  font-size: 11px;
  font-weight: bold;
}

.language-box {
  box-sizing: content-box;
  padding: 6px;
  font-family: monospace;
  font-size: 18px;

  &.selected {
    border: 2px solid green;
  }
}

.super-modal-root {

  &.ReactModal__Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(205, 205, 205, 0.75);
  }

  .Modal {
    position: relative;
    top: 0px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    max-height: 70vh;
    padding: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    color: #343534;
    min-width: 600px;
  }


  .modal-close-right {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .modalHeader {
    min-height: 70px;
    box-shadow: 0px 2px 10px 3px rgba(230, 230, 230, 0.6);
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
  }


  .modalFooter {
    min-height: 80px;
    box-shadow: 0px -2px 10px 3px rgba(230, 230, 230, 0.6);
    box-sizing: content-box;
    display: flex;
    align-items: center;
    padding: 0 50px;
    justify-content: space-between;
    z-index: 100;
  }

  .modalContent {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
  }

  .subjectItem {
    display: flex;

    .image {
      width: 180px;
      min-width: 180px;
      height: 100px;
      padding: 10px;
      object-fit: cover;
      position: relative;

      &:before {
        transition: ease 0.2s all;
        position: absolute;
        content: "";
        background-image: url("/static/img/icon-play.png");
        background-repeat: no-repeat;
        background-size: 30px auto;
        background-position: center center;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .text-holder {
      padding: 10px 0;
      color: #343534;
      flex: 2;
    }

    .title {
      font-size: 18px;
      padding: 0;
    }

    .description {
      font-size: 13px;
      max-width: 500px;
      opacity: 0.8;
    }

    .add-remove-btn {
      width: 60px;
      height: 60px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-weight: bold;
      background-color: rgb(220, 237, 230);
      color: #32be3f;

      &.selected {
        background-color: rgb(190, 190, 190);
        color: black;
      }
    }

  }

}

.super {

  a {
    color: #20A26B;
    text-decoration: underline;
  }

  h3 {
    padding-bottom: 2px;
    border-bottom: 1px solid #E7E5E1;
  }

  h4 {
    font-size: 1rem;
    padding: 30px 0 20px;
  }

  .padded-container {
    padding-left: 20px;
  }

  .form-inline {
    align-items: flex-start;
  }
}
