.manager-user-table .progress-column {
  width: 70%;
}

.subject-title-column, .list-name-column {
  white-space: nowrap;
  padding: 12px !important;
}


