.left-banner {
  padding: 20px;
  background: #F7F7F5;
}

.right-banner {
  padding: 20px;
  background: #EFEEED;

  &.placeholder {
    padding: 20px;
    margin: 20px;
  }
}

.list-select {
  padding: 20px;

  button {
    margin: 0;
  }

  .img {
    padding: 10px;

    img {
      width: 50px;
    }
  }
}


// Placeholder when drag subjects is loading
.right-banner-placeholder {
  .loading-group-automation {
    width: 100%;
    border-radius: 8px;
    border-left: #fafafa 60px solid;
    height: 90px;
    position: relative;
    background: rgba(255, 255, 255, .8);
    margin: 8px 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 4px 4px 3px rgba(0, 0, 0, 0.01);
    width: 550px;

    &:after {
      position: absolute;
      content: "";
      top: 15px;
      left: 180px;
      background: rgba($desertstorm, .3);
      width: 180px;
      height: 20px;
    }

    &:before {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      background: rgba(0, 0, 0, .04);
      width: 154px;
      height: 90px;
    }
  }

  .loading-group-question {
    width: 100%;
    border-radius: 8px;
    border-left: #fafafa 260px solid;
    height: 20px;
    position: relative;
    background: rgba(255, 255, 255, .8);
    margin: 8px 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 4px 4px 3px rgba(0, 0, 0, 0.01);
    width: 550px;

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 170px;
      background: rgba($desertstorm, .3);
      width: 80px;
      height: 20px;
    }

    &:before {
      position: absolute;
      content: "";
      top: 0px;
      left: 50px;
      background: rgba(0, 0, 0, .04);
      width: 80px;
      height: 18px;
    }
  }
}

.loading-group-image {
  position: absolute;
  background: rgba($desertstorm, 0.9);
  background-image: url("/static/img/automation-init-description.png");
  background-position: center center;
  background-size: 350px auto;
  background-repeat: no-repeat;
  top: 14px;
  width: 400px;
  height: 200px;
  top: 240px;
  left: 50%;
  margin-left: -200px;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 2px 33px -5px rgba(0, 0, 0, 0.45);


}


.subjectPanel-small {
  display: flex;
  flex-direction: row;
}

.scheduleResult {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
  }
}

.scheduleImage {
  width: 180px;
  min-width: 180px;
  height: 100px;
  padding: 10px;
  object-fit: cover;
  position: relative;

  &:before {
    transition: ease 0.2s all;
    position: absolute;
    content: "";
    background-image: url("/static/img/icon-play.png");
    background-repeat: no-repeat;
    background-size: 30px auto;
    background-position: center center;
    background-color: rgba($color: #000000, $alpha: .2);
    width: 150px;
    height: 80px;
    border-radius: 2px;
  }

  img {
    height: 100%;
    border-radius: 2px;
    max-width: 100%;
  }

}


.dragItem {
  display: flex;
  flex-direction: row;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;


  .dragLeft {
    display: flex;
    min-width: 35px;
    width: 35px;
    background: #F6F6F5;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    img {
      align-self: center;
      width: 20px;
    }
  }

  .image {
    width: 180px;
    min-width: 180px;
    height: 100px;
    padding: 10px;
    object-fit: cover;
    position: relative;
    margin: 0 -10px;

    &:before {
      transition: ease 0.2s all;
      position: absolute;
      content: "";
      background-image: url("/static/img/icon-play.png");
      background-repeat: no-repeat;
      background-size: 30px auto;
      background-position: center center;
      background-color: rgba($color: #000000, $alpha: .2);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 10px;
      margin-right: 20px;
      border-radius: 2px;
    }

    img {
      height: 100%;
      border-radius: 4px;

    }
  }

  .text-holder {
    padding: 10px 0;
    color: $heavymetal;
    width: 100%;
    margin-left: 12px;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;

    .subject-name {
      font-size: 18px;
    }

    .subject-duration {
      font-size: 12px;
      font-weight: 600;
      text-align: right;
    }

    .subject-modified-label {

      padding: 2px 8px;
      background: rgba(213, 128, 28, 0.1);

      border-radius: 4px;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      color: #D5801C;
    }
  }

  .description {
    font-size: 13px;
    max-width: 500px;
    opacity: 0.8;
  }
}


.dragSelect {
  &.left-banner {
    background: #F7F7F5;
  }

  ul {
    list-style: none;
    padding-inline-start: 0px;

    li {
      line-height: 30px;
    }

    input {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      // Box.
      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
      }

      // Box hover
      &:hover + label:before {
        background: #6C9D55;
      }

      // Box focus
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        background: #6C9D55;
      }

      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 12px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
        transform: rotate(45deg);
      }
    }

    input:checked + ul > li {
      color: #6C9D55;
    }

  }
}


.dateHolder {
  background: white;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 20px -2px rgba(0, 0, 0, 0.16);

  .dragItem {
    background: $desertstorm;
    margin-bottom: 10px;
  }

  .img {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 40px;

    }
  }

  .datePanel {
    display: flex;
    flex-direction: column;
    background: $aspargus;
    padding: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    color: white;

    &.planned {
      background: $goldengrass;

    }

    .bottom {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      .btn {
        justify-content: flex-end;
      }
    }

  }

  .subjectPanel {
    padding: 20px;
  }

  .subjectPanel-small {
    padding: 20px;
    display: flex;
  }

  .date {
    font-size: 0.9em;
    opacity: 0.8;
  }

  .date_weekday {
    font-size: 0.8em;
    opacity: 0.8;
  }

  .date_exp {
    font-size: 0.6em;
    opacity: 0.4;
  }


}

.automation-status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.information-box {
  background: #EFEEED;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 4px 4px 11px 1px rgba(0, 0, 0, 0.03);

}

.automation {
  .simple-list {
    padding: 25px;

    .link {
      color: $aspargus;
      font-weight: 600;
    }

    .details {
      padding: 14px 0;

      div {
        padding: 5px 0;
        border-bottom: 1px solid #fcfcfc;
      }


    }

  }
}
