@import './src/css/variables';

.simple-table.MuiTable-root {
    .MuiTableRow-head,
    .MuiTableRow-root:not(:last-child) {
        border-bottom: 1px solid rgba($heavymetal, 0.05);
    }

    .MuiTableCell-head {
        padding: 0 0 5px 0;

        @media (max-width: 1200px) {
            padding: 0 8px 5px 8px;
        }
    }

    .MuiTableCell-root.MuiTableCell-body {
        padding: 16px 0;
        background-color: transparent;
        text-shadow: none;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: $heavymetal;

        @media (max-width: 1200px) {
            padding: 16px 8px;
        }

        .row-title {
            font-size: 16px;
            font-weight: 600;
            color: $heavymetal;
        }
    }
}

.simple-table-pagination.MuiTablePagination-root {
    overflow: visible !important;

    .MuiTablePagination-toolbar {
        padding: 0;
    }

    &.MuiTableCell-root {
        border: none;
    }

    .MuiInputBase-root {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 30px 0 10px;
    }
}

.global-filter {
    margin-top: 20px;

    .MuiInputLabel-root {
        margin-left: 5px;
    }
}
