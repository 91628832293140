
.embed-holder {
  border: 2px #ccc dashed;
  padding: 10px;
  margin: 50px auto 20px auto;
  width: 520px;


  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  body {
    font-family: 'Nunito Sans', sans-serif;
    color: #343534;

  }

  .language-chooser {
    border-radius: 5px;
    padding: 5px 10px;
    display: block;
    position: relative;
    margin-top: 5px;
  }

  .selected {
    background: white;
    width: 120px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
  }

  .nav-item-dropdown {
    min-width: 140px;
    display: none;
    opacity: 1;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    font-size: 15px;


  }

  .language-chooser .nav-item-dropdown {
  }

  .language-chooser:hover .nav-item-dropdown {
    position: absolute;
    display: block;
    opacity: 1;
    bottom: 46px;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.05);
    left: 1px;
  }


}

.flag {
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 22px auto;
  position: absolute;
  right: 0;
  font-size: 14px;

  &.static-flag {
    position: static;
    display: inline-block;
  }
}

.nav-item-dropdown a {
  text-decoration: none;
  color: #343534;
}

.flag:hover {
  background-color: #eeeeee;
}

.flag.english {
  background-image: url('/static/img/flags/english.svg');
}

.flag.english-gb {
  background-image: url('/static/img/flags/english_uk.svg');
}

.flag.spanish {
  background-image: url('/static/img/flags/spanish.svg');
}

.flag.italian {
  background-image: url('/static/img/flags/italian.svg');
}

.flag.french {
  background-image: url('/static/img/flags/france.svg');
}

.flag.deutsch {
  background-image: url('/static/img/flags/deutch.svg');
}

.flag.icelandic {
  background-image: url('/static/img/flags/icelandic.svg');
}

.flag.danish {
  background-image: url('/static/img/flags/danish.svg');
}

.flag.swedish {
  background-image: url('/static/img/flags/swedish.svg');
}

.flag.norwegian {
  background-image: url('/static/img/flags/norwegian.svg');
}

.brand {
  position: absolute;
  float: right;
  right: 5px;
  bottom: 14px;
  width: 90px;
}

.brand img {
  width: 90px;
}

.embed-code {
  overflow-wrap: break-word;
  background: #343534;
  color: $desertstorm;
  font-size: 0.8em;
  width: 500px;
  border-radius: 5px;
  padding: 14px 8px;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  margin: 0 auto;
  width: 520px;
}

xmp, pre {
  white-space: pre-wrap;
  margin: 0;
}
