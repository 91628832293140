@import "./src/css/variables";

.key-behaviour-chart-header {
  font-size: 16px;
  font-weight: 700;
  color: $heavymetal;
  display: flex;
  align-items: center;
  margin-top: 30px;

  .count-in-line {
    background: #E4F1EC;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: $aspargus;
    font-weight: 700;
    font-size: 10px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.bar-chart-assessment {
  padding: 10px;
}
