.training-wizard, .wizard-fullscreen {
  &.lightbox-like {
    padding: 20px;
  }

  h3 {
    font-weight: 700;
    font-size: 1.4em;
    margin: 10px 0;
  }


  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $heavymetal;
  z-index: 1001;

  .btn {
    padding: 0.7rem 2.4rem;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.04em;
    transition: 0.2s ease all;

    &.btn-outline-success {
      border: 1px solid $aspargus;
      color: $aspargus;

      &:hover {
        color: white;
      }
    }

    &.btn-secondary {
      color: $aspargus;

      &:hover {
        color: white;
      }
    }

    &.btn-outline-secondary {
      color: lighten($heavymetal, 20);
      border: 1px solid lighten($heavymetal, 40);
      background: none;

      &:hover {
        color: lighten($heavymetal, 10);
        border: 1px solid lighten($heavymetal, 10);
        background: lighten($heavymetal, 76);
      }
    }
  }
}

.wizard-fullscreen {
  padding: 0px;
}

.d-flex {
  &.responsive-filters {
    @media screen and (max-width: 899px) {
      flex-direction: column;
      .filters {
        margin-right: 0;
      }
    }
  }
}

.icon-filters {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 25px;
  background-image: url("/static/img/icon-filters.svg");
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position: center center;
  align-self: flex-end;

  &.open {
    background-image: url("/static/img/icon-filters.svg");
  }
}

.filters {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 250px;
  padding: 20px 0 !important;
  height: fit-content;
  transition: ease-in-out 0.2s all;
  @media screen and (max-width: 900px) {
    padding: 0 !important;
    position: relative;
    display: flex;
    flex-direction: row;
    display: block;
    float: none;
    position: relative;
    max-height: 0;
    padding-bottom: 20px;
    overflow: hidden;
    opacity: 0;
    margin-top: 0px !important;
    a {
      padding-right: 50px;
    }

  }

  &.open {
    padding: 10px 0 !important;
    max-height: none;
    opacity: 1;
    margin-top: 20px !important;

  }
}

.training-filter-steps, .sidebar-steps {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 250px;
  padding: 20px 0 !important;
  height: fit-content;


  .step {
    white-space: nowrap;
    line-height: 42px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 16px;
    color: lighten($heavymetal, 10);
    font-weight: 600;
    border-left: 4px solid white;
    justify-content: space-between;
    flex-direction: row;
    display: flex;

    &.selected {
      border-left: 4px solid $aspargus;
      color: $aspargus;
    }

    &.active {
      border-left: 4px solid $aspargus;
      color: $aspargus;

      span {
        font-weight: 600;
      }
    }

    &.enabled {
      opacity: 1;
      cursor: pointer;
      color: $heavymetal;

      &:hover {
        color: lighten($aspargus, 10);
        border-left: 4px solid lighten($heavymetal, 40);
      }
    }

    .step-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .trainings-count, .step-count {
      padding: 0 10px;
      text-transform: initial;
      font-size: 12px;
      text-align: right;
    }
  }
}

.training-wizard-steps {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 312px;
  margin-right: 48px;

  @media only screen and (min-width: 1440px) {
    margin-right: 0;
  }
}


.training-list {
  flex: 1;
  box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.01), 0px 0px 2px rgba(0, 0, 0, 0.01);
  padding: 10px;

  .training-progress {
    padding: 20px;
    margin: 10px 0;
    transition: ease 0.2s all;
    box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.01), 0px 0px 2px rgba(0, 0, 0, 0.01);
    font-size: 14px;
    border-bottom: 1px solid lighten($heavymetal, 70);
    @media (max-width: 899px) {
      padding: 20px;
      margin: 20px 0;
      box-shadow: 0px 2px 2px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);

    }

    .nowrap {
      padding: 0;
      @media (max-width: 899px) {
        padding: 10px 20px;
      }
    }

    strong {
      font-weight: 600;
      color: lighten($heavymetal, 20)
    }

    &:last-child {
      border-bottom: none;

    }

    &:hover {
      box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
      @media (max-width: 899px) {
        box-shadow: none;
      }
    }

    .training-progress-title {
      display: flex;
      justify-content: space-between;
      @media (max-width: 899px) {
        display: block;
      }

      .details-time {
        font-size: 0.8rem;
      }
    }

    .row {
      margin-top: 5px;
    }

    h3 {
      color: $heavymetal;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    h4 {
      font-size: 0.8rem;
      font-weight: 600;
      color: lighten($heavymetal, 20);
    }

    .progress {
      border: 0;
      margin-top: 10px;
      height: 0.3rem;
      padding: 0;
      background: #F0EFEF;
      border-radius: 3px;
      display: block;

      .progress-bar {
        border-radius: 3px;
        height: 4px;
      }

    }
  }
}

.training-wizard-header, .wizard-fullscreen-header {
  background: white;
  height: 70px;
  box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.training-wizard-close, .wizard-fullscreen-close {
  background: white;
  position: absolute;
  left: 48px;
  top: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}


.subjectItem {
  display: flex;
  border-bottom: 1px solid #fcfcfc;

  &.disabled {
    opacity: 0.4;
  }

  .image {
    width: 180px;
    min-width: 180px;
    height: 100px;
    padding: 10px;
    object-fit: cover;
    position: relative;
    margin: 0 -10px;

    &:before {
      transition: ease 0.2s all;
      position: absolute;
      content: "";
      background-image: url("/static/img/icon-play.png");
      background-repeat: no-repeat;
      background-size: 30px auto;
      background-position: center center;
      background-color: rgba($color: #000000, $alpha: .2);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 10px;
      margin-right: 20px;
      border-radius: 2px;
    }

    img {
      height: 100%;
      border-radius: 4px;

    }
  }

  .text-holder {
    padding: 10px 0;
    color: #343534;
    flex: 2;
    margin-right: 46px;
    margin-left: 12px;
  }

  .title {
    font-size: 18px;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .subject-modified-label {

      padding: 2px 8px;
      background: rgba(213, 128, 28, 0.1);

      border-radius: 4px;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      color: #D5801C;
    }
  }

  .description {
    font-size: 13px;
    max-width: 500px;
    opacity: 0.8;
  }

  .add-remove-btn {
    width: 60px;
    height: 60px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    background-color: rgb(220, 237, 230);
    color: #32be3f;

    &.selected {
      background-color: rgb(190, 190, 190);
      color: black;
      border-color: lighten($aspargus, 100);
    }
  }
}

.pdf-icon {
  width: 40px;
  margin-left: 10px;
}


.training-wizard-footer, .wizard-fullscreen-footer {
  background: white;
  height: 80px;
  box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
  box-sizing: content-box;
  display: flex;
  align-items: center;
  z-index: 100;

  .btn {
    margin: 0 20px;
    @media screen and (max-width: 900px) {
      margin: 0;
      padding: 5px 20px;
    }

    &.continue {
      margin-left: auto;
    }
  }

  .price {
    font-size: 0.9em;

    strong {
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 20px;
      font-size: 0.9em;

    }

    .price-description {
      font-size: 0.8em;
      opacity: 0.8;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 380px;
    margin-right: 380px;
    width: 100%;

    @media only screen and (min-width: 1440px) {
      max-width: 660px;
      margin-left: auto;
      margin-right: auto;
    }

    &.center {
      margin: 0 auto;
    }
  }
}

.wizard-fullscreen-body.loading .braintree-dropin {
  opacity: 0.2;
}

body #Cardinal-ModalContent, body #Cardinal-ModalContent.size-02 {
  width: 500px;
  height: 500px;

  > iframe {
    width: 500px !important;
    height: 500px !important;
  }
}


.training-wizard-body, .wizard-fullscreen-body {
  display: flex;
  height: 100%;
  overflow: hidden;
  background: white;

  .training-wizard-body-header, .wizard-fullscreen-body-header {
    display: flex;
    max-width: 910px;
    justify-content: space-between;
    align-items: baseline;

    h3 {
      margin: 40px 0 4px 0;
    }

    .action {
      display: flex;
      align-items: center;
      margin-left: 30px;

    }
  }

  form {
    padding: 20px;
  }
}

.wizard-fullscreen-body .wait-overlay {

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: rgba(120, 120, 120, 0.3);
}


.training-wizard-content {

  padding-right: 20px;
  width: 100%;

  @media only screen and (min-width: 1440px) {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }

  // &.full-width {
  //   max-width: unset;
  // }

  .form-email {
    font-weight: 600;
    font-size: 16px;
    margin: 10px 0;
  }

  h3 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
  }

  h4 {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 33px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;

    &.green {
      color: $aspargus;
    }
  }

  p {
    font-size: 14px;
  }

  button {
  }

  label {

  }

  small {
    margin: 10px 0 5px 0;
    font-size: 14px;
    line-height: 1.4em;
    opacity: 0.6;
  }

  flex: 2;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;

  .dragZone {
    background: white !important;

    .dragLeft {
      background: #fefefe;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;

      img {
        transition: ease 0.2s all;
        opacity: 0;
      }
    }

    .remove-subject {
      background: #fefefe;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      display: flex;
      min-width: 40px;
      width: 40px;
      align-items: center;
      justify-content: center;

      img {
        padding: 5px;
        width: 25px;
        cursor: pointer;
        transition: ease 0.2s all;
        opacity: 0;
      }
    }

    .dragItem {
      border-radius: 2px;
      transition: ease 0.2s all;
      margin-right: 50px;

      &.questions {
        border-collapse: collapse !important;
        border-spacing: 0;

        td:nth-child(2) {
          min-width: 220px !important;
        }

        th:nth-child(2) {
          min-width: 220px !important;
        }
      }

      &.MuiTableRow-root {
        &:active {
          box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.04);
          border-spacing: 0;

          td:nth-child(2) {
            min-width: 220px !important;
          }

          th:nth-child(2) {
            min-width: 220px !important;
          }

        }
      }

      &:hover,
      &:active {
        box-shadow: 0px 4px 14px rgba(29, 30, 29, 0.04);

        .dragLeft {
          img {
            opacity: 1 !important;
          }
        }

        .remove-subject {
          img {
            opacity: 1 !important;
          }
        }
      }
    }

    .MuiTable-root {
      border-collapse: separate;
      border-spacing: 5px 1em;

      .MuiTableRow-head {
        border-bottom: 1px solid #ccc;
      }

      .MuiTableCell-body {
        border-bottom: none;
      }

      .MuiTableRow-root {
        margin: 5px 0;
        height: auto;

        .MuiTableCell-root {
          height: auto;
          padding: 15px 0;

          &.dragCell {
            width: 30px;
          }

          .subject-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
          }

        }
      }
    }
  }

  select, .bigInput {
    background: rgba(240, 239, 239, 0.2);
    border: 1px solid #F0EFEF;
    box-sizing: border-box;
    font-size: 14px;
    height: 40px;
    border-radius: 4px;

    &:focus {
      border: 1px solid rgba($heavymetal, 0.2);
      outline: none;
    }
  }

  textarea.bigInput {
    height: auto;
  }

  // lists
  .simple-list {
    font-size: 16px;

    .container-checkbox {
      font-size: 16px;
      font-weight: 700;


      .checkmark {
        width: 20px;
        height: 20px;
        border: 2px solid lighten($heavymetal, 20);
        border-radius: 2px;
        top: 6px;
        pointer-events: none;

      }

      .checkmark:after {
        border-width: 0 3px 3px 0 !important;
        border: 2px solid #676867;
        top: 0px;
        left: 5px;
        width: 6px;
        height: 13px;
      }

      input:checked ~ .checkmark {
        background: $aspargus;
        border-color: $aspargus;

        &:after {
          border-color: white !important;
        }
      }
    }
  }

}

.training-template {

  max-width: 300px;
  min-width: 250px;
  background-color: white;
  cursor: pointer;
  border: 1px solid white;
  margin-bottom: 15px !important;
  transition: ease 0.2s all;
  position: relative;

  &.training-template-20 {
    border-bottom: 4px solid #FFEB00;

    &:after {
      content: "";
      position: absolute;
      width: 34px;
      height: 34px;
      position: absolute;
      right: 20px;
      top: 20px;
      background-image: url("/static/img/icon-dummies.svg");
      background-size: 34px auto;
      transform: rotate(4deg);
      background-repeat: no-repeat;
    }
  }

  &.disabled {
    opacity: 0.4;

    .card-body {
      cursor: default;
    }
  }

  &.border-secondary {
    border-color: #ffffff !important;
  }

  &.selected {
    color: green;
    background-color: #f0faf7;
  }

  .card-body {
    font-size: 13px;
    box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.04);
    transition: ease 0.2s box-shadow;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
    }

    .card-title {
      h3 {
        margin: 0 0 10px 0;
      }
    }

    span {
      font-weight: 700;
      display: block;
      margin: 15px 0 0 0;
    }
  }
}

.training-line {
  box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 10px;
}


.modalFooter .container-checkbox.small .checkmark {
  top: 3px;
}

.training-schedule {
  h5 {
    font-size: 14px;
    color: lighten($heavymetal, 30);
    font-weight: 600;
    margin-top: 40px;
  }


  .training-schedule-options {
    display: flex;
    padding: 10px 0 40px 0;

    label {
      &.checked {
        font-weight: 700;
      }
    }

    .container-radiobutton {
      padding-left: 30px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-right: 20px;

      .radiomark {
        width: 22px;
        height: 22px;

        &:after {
          left: 2px;
          top: 2px;
          width: 14px;
          height: 14px;
        }
      }
    }

  }

  .training-schedule-frequency {
    padding: 20px 0;
    border-bottom: rgba($heavymetal, 0.1) 1px solid;
    margin-bottom: 20px;

    .delay {
      padding: 0;
      margin: 0;
      padding-left: 14px;
      font-size: 16px;
      display: flex;
      align-items: center;
      color: rgba($heavymetal, 0.8);
    }

    .delete {
      display: flex;
      align-items: center;
    }
  }


}

// Modal
.ReactModal__Content {
  &.schedule {
    //width: 500px;
    max-width: 90%;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;

    &.slim {
      width: 500px;
    }
  }

  .modalHeader {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0 10px 20px;

    h3 {
      font-weight: 700;
      font-size: 1.4em;
    }

    select {
      margin-left: 20px;
    }
  }

  .modalFooter {
    background: white;
    justify-content: flex-end;
  }

  .modalFooter.justify-between {
    justify-content: space-between;
  }

  .modalContent {
    p {
      color: rgba($heavymetal, 0.9);
    }

    .flex-row {
      justify-content: space-between;

      input {
        width: 100%;
      }

      .form-holder {
        width: 100%;
        margin: 5px 10px 0 5px;
      }
    }
  }

  .schedule-preview-row {
    padding: 20px 0;
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid lighten($heavymetal, 70);

    h5 {
      font-weight: 600;
      color: $aspargus;
      padding: 10px 0 0 10px;
      margin: 0;
    }

    .title {
      padding-top: 8px;
      padding-left: 30px;
      font-size: 16px;
      font-weight: 400;
    }

    .pretitle {
      margin: 0;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  select {
    background: rgba(240, 239, 239, 0.2);
    border: 1px solid #F0EFEF;
    box-sizing: border-box;
    font-size: 14px;
    height: 40px;
    border-radius: 4px;
  }

  .add-remove-btn {
    background: rgba($aspargus, 0.1);
    border-radius: 4px;
    height: 48px;
    transition: 0.2s ease all;
    align-self: center;
    font-weight: 300;
    pointer-events: all;
    transition: ease 0.2s background-color;

    &.selected {
      background: rgba($heavymetal, 0.05);
      font-size: 12px;
      color: $heavymetal;

    }

    &:hover {
      background: rgba($aspargus, 0.3);
      cursor: pointer;
    }
  }
}

.right-sidebar {
  padding: 40px 0px;
  width: 284px;
  margin-left: 48px;
  margin-right: 48px;
}

.pro-tip {
  @media (max-width: 1500px) {
    display: none;
  }

  &.static {
    position: relative;
    top: unset;
    right: unset;
  }

  position: absolute;
  top: 100px;
  right: 30px;
  width: 250px;
  box-shadow: 0px 4px 24px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.08);
  padding: 5px 20px;
  background-color: white;

  .star {
    background: white;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    background-image: url("/static/img/icon-star.svg");

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    margin: 12px 0 !important;
    padding: 0;
  }

  p {
    line-height: 1.5em;
  }

}

.report-root {
  background: white;

  .block {
    padding: 0;
    margin: 0;
  }

  .reactable-column-header {
    background-color: rgb(241, 241, 241);
    border-top: 1px solid rgb(184, 184, 184);
    border-bottom: 1px solid rgb(184, 184, 184);
  }

  .manager-user-table table th {
    background: inherit;
    white-space: nowrap;


  }

  header .header {
    justify-content: space-between;
  }

  .table thead tr:last-child {
    border-bottom: 1px solid rgb(184, 184, 184);
  }

  .manager-user-table {
    overflow: initial;
  }
}

.report-root-users {
  table thead th {
    font-size: 10px;

  }

  .table {
    width: auto;

  }

  .manager-user-table table th {

    .subject-name {
      writing-mode: vertical-lr;
      font-feature-settings: "vkrn", "vpal";
      display: inline-block;
    }
  }

  .table th, .table td {
    padding: 0.15rem;
  }
}

.last-trainings {
  display: inline-flex;

  h3 {
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      right: -20px;
      top: 18px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid lighten($heavymetal, 10);
    }
  }
}

.status-row {
  &.disabled {
    opacity: 0.7;
  }
}
