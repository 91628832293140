.select-from-text {
  height: 40px;
  width: 240px;
  border: 1px solid #e3e3e3;
  color: #616263;
  position: relative;
  border-radius: 3px;
  background: lighten($snowdrift, 10);
  border-radius: 5px;
  margin: 10px 0;
  font-size: 0.6em;

  @media (max-width: 900px) {
    text-align: right;
  }

  .title {
    position: absolute;
    top: -20px;
    left: 10px;
  }

  &:after {
    content: "";
    background: url('/static/img/icon-arrow-down.png') no-repeat center center;
    padding: 12px 8px;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
  }

  .select-form {
    height: 40px;
    width: 100%;
    padding: 5px 15px;
    background: none;
    border: 0;
    outline: none;
    font-size: 1.4em;
    -webkit-appearance: none; /* for webkit browsers */
    -moz-appearance: none; /* for firefox */
    appearance: none; /* for modern browsers */
  }
}


#signin {
  margin: 0 10px;
}

/* remove default caret for ie */
.select-form::-ms-expand {
  display: none;
}


.reactable-filter-input {
  color: black;

  &:-webkit-input-placeholder:before {
    color: #666;
    content: "Filter users";
  }
}

.subject-optional-note a, .public-DraftStyleDefault-block a {
  color: #324cdd;

  &:hover {
    color: #324cdd;
    text-decoration: underline;
  }
}
