@import "./src/css/variables";

.line.list-overview {
  border-top: 1px solid lighten($desertstorm, 4);
  padding: 2px 4px;
  line-height: 28px;
  font-size: 0.9em;

  div {
    display: flex;
    align-items: center;
  }

  &:last-child {
    border-bottom: 1px solid $desertstorm;
  }

  &:nth-child(even) {
    background-color: #fefefe;
  }

  .text-right {
    display: flex;
    justify-content: flex-end;
  }
}

.employee-list-disabled {
  color: rgba($heavymetal, 0.4);
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

