.report-header {
  .bars-holder {
    padding: 0 20px;

    label {
      font-weight: 100;
      font-size: 0.8em;
      padding: 20px 0 0 0;

    }
  }

  .title {
    display: flex;
    align-items: center;

    .btn {
      padding-left: 16px;
      padding-right: 4px;
      font-size: 0.8em;
    }
  }

  p {
    font-size: 0.9em;
    padding: 0;
    margin: 0;
  }

  h5 {
    font-size: 0.8em;
    opacity: 0.5;
    padding: 10px 0 0 0;
    margin: 0;
  }

  .table-dark-bg {
    padding-bottom: 20px;
  }

  .block {
    background: white;
  }
}


.manager-content {
  .campaign-status-progress {
    .bg-success {
      min-width: 30px;
      color: white;
      background: $aspargus;
      border-radius: 2rem;
    }
  }

  .campaign-progress-bar {
    border-radius: 5px;
    background: none;
    border-radius: 5px;
    padding: 50px 0 20px 0;
    margin-bottom: 40px;

    #steps {
      width: 505px;
      margin: 10px auto;
    }

    .step {
      width: 40px;
      height: 40px;
      background-color: $white;
      display: inline-block;
      border-color: transparent;
      border-radius: 50%;
      color: darken($desertstorm, 10);
      text-align: center;
      line-height: 36px;

      img {
        width: 40px;
        height: auto;
        opacity: 0.2;
      }
    }

    .step:first-child {
      line-height: 40px;
    }

    .step:nth-child(n+2) {
      margin: 0 0 0 100px;
      transform: translate(0, -4px);
    }

    .step:nth-child(n+2):before {
      width: 55px;
      height: 3px;
      display: block;
      background-color: darken($desertstorm, 10);
      transform: translate(-75px, 21px);
      content: '';
    }

    .step:after {
      width: 150px;
      display: block;
      transform: translate(-55px, 3px);
      color: darken($desertstorm, 20);
      content: attr(data-desc);
      font-weight: 400;
      font-size: 13px;
    }

    .step:first-child:after {
      transform: translate(-55px, -1px);

    }

    .step.active {
      //border-color: $aspargus;
      color: white;
      //background-color: $aspargus;
      img {
        opacity: 1;
      }
    }

    .step.active:before {
      background: linear-gradient(to right, darken($desertstorm, 20) 0%, $aspargus 100%);
    }

    .step.active:after {
      color: $aspargus;
      font-weight: 700;
      font-size: 0.8em;;

    }

    .step.done {
      background-color: $aspargus;
      border-color: $aspargus;
      color: $white;
    }

    .step.done:before {
      background-color: $aspargus;
    }

    .step1 {
      margin: 20px auto;
      background: lighten($snowdrift, 10);
      border: 1px solid darken($desertstorm, 10);
      border-radius: 5px;
      text-align: center;
    }
  }
}
