.nav-tabs {
  display: flex;
  border-bottom: 1px solid #F5F3F3;
  align-items: center;
  padding-left: 40px;
  font-size: 16px;
  @media (max-width: 899px) {
    padding: 0px;
  }
  /* special case  */
  &.tabs-dashboard {
    margin-left: -40px;
    margin-right: -40px;
    border-top: 1px solid #F5F3F3;
    background: #FAF9F9;
  }

  &.tabs-training {
    margin-left: -30px;
    margin-right: -30px;
  }

  .nav-item {
    transition: ease 0.2s all;
    font-weight: 600;
    border-bottom: 4px solid transparent;
    margin-right: 20px;
    @media (max-width: 899px) {
      margin-right: 0;
    }
    cursor: pointer;

    &.active {
      color: $shiraz;
      border-color: transparent;
      border-bottom: 4px solid $shiraz;

      a {
        color: $shiraz;
      }
    }

    &:hover, &:focus {
      color: lighten($heavymetal, 10);
      border-color: transparent;
      border-bottom: 4px solid $shiraz;
    }
  }
}
