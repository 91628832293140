@import "./src/css/variables";

.preview-questions-modal {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 95vh;
    min-height: 75vh;

    .modalHeader {
        height: 80px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0;
        color: rgba($heavymetal, 0.6);

        .close-btn-with-text {
            position: absolute;
            left: 53px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;

            img {
                margin-right: 40px;
                cursor: pointer;
            }
        }
    }

    .modalContent{
        padding: 0;
        display: flex;
        min-height: 75vh;
        flex-direction: column;

    }

    .modalFooter{
        margin-top: auto;
        min-height: 80px;
        bottom: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .footer-buttons {
            display: flex;
            width: 90%;
        }
    }
}

.review-questions-modal {

    .MuiDialog-paperFullScreen {
        height: 100vh;
        width: 100vw;
        max-width: 100vw;
    }

    .modalHeader {
        height: 80px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0;
        color: rgba($heavymetal, 0.6);

        .close-btn-with-text {
            position: absolute;
            left: 53px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;

            img {
                margin-right: 40px;
            }
        }

        .heading-with-score {
            display: flex;
            align-items: center;
            letter-spacing: 4%;

            h3 {
                font-size: 20px;
                font-weight: 800;
                color: $heavymetal;
                margin: 0 8px 0 0;
                padding: 0;
            }

            .score-group {
                font-weight: 700;
                font-size: 20px;
                display: flex;
                align-items: center;

                span {
                    margin: 0 16px 0 8px;
                }

                .circle-progress-cropped {
                    margin-top: -10px;
                }
            }
        }

        // .assessment-show-results-for {
        //     padding: 0;
        //     position: absolute;
        //     right: 53px;

        //     .MuiOutlinedInput-input {
        //         border: none;
        //     }
        // }
    }

    .modalContent{
        margin-bottom: 80px;
        padding: 0;
        display: flex;
        flex-direction: column;

        .modalFooter{
            .footer-buttons {
                display: flex;
                width: 90%;
            }
        }


        .assessment-preview-question {
            display: flex;
            width: 100%;
            flex: 1;
        }

        .assessment-presentation {
            padding: 0;
        }

        &.loading-centered {
            display: flex;
            justify-content: center;
            padding: 100px;
        }
        

        .assessment-block {
            padding: 70px 65px 0 170px !important;
            overflow: scroll;

            /* Hide scrollbars*/
            -ms-overflow-style: none;  /* for IE and Edge */
            scrollbar-width: none;  /* for Firefox */

            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari and Opera */
            }

            .question {
                max-width: 560px;
                width: 100%;
                margin: 0 auto;

                .image>img {
                    width: 100%;
                }

                h3 {
                    margin-top: 0;
                }
            }
    }
    
        .assessment-block-type-2 {
            padding: 70px 170px 0 65px;
            height: 100%;

            .assessment-carousel{
                max-width: initial;
                padding: 0;
            }
        }
    }

    .modalFooter{
        position: absolute;
        width: 100vw;
        bottom: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .aaa {
        position: absolute;
        display: flex;
        width: 100%;
        bottom: 0;
    }
    .push {
        margin-left: auto;
    }

    .actions {
        width: 100%;
        display: flex;
        margin-left: 250px;
        @media (max-width: 992px) {
            margin-left: unset;

        }
        .mobile-arrow-back {
            @media (max-width: 992px) {
                padding: 0.7rem 0rem;
                width: 70px;
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
                background-image: url('/static/img/icon-arrow-back.svg');
                background-color: rgba($heavymetal,0.2);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 14px auto;
            }
        }
        &.answers {
            width: 90%;
            max-width: 90%;
            margin: 0 auto;
            button {
                margin: unset;

            }
        }
    }

    
}